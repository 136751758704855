@import './din-pro-cond/stylesheet.scss';

font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-light-italic-latin-extd.woff2') format('woff2'),
    url('opensans/open-sans-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-light-italic-latin.woff2') format('woff2'),
    url('opensans/open-sans-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-light-italic-cyrillic.woff2') format('woff2'),
    url('opensans/open-sans-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-light-latin-extd.woff2') format('woff2'),
    url('opensans/open-sans-light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-light-latin.woff2') format('woff2'),
    url('opensans/open-sans-light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-light-cyrillic.woff2') format('woff2'),
    url('opensans/open-sans-light.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-bold-latin-extd.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-bold-latin.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-bold-cyrillic.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: normal;
  font-display: swap;

  src: url('opensans/open-sans-italic.woff2') format('woff2'),
    url('opensans/open-sans-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-regular-latin-extd.woff2') format('woff2'),
    url('opensans/open-sans-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-regular-latin.woff2') format('woff2'),
    url('opensans/open-sans-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-regular-cyrillic.woff2') format('woff2'),
    url('opensans/open-sans-regular.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;

  src: url('opensans/open-sans-semi-bold-italic.woff2') format('woff2'),
    url('opensans/open-sans-semi-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-semi-bold-latin-extd.woff2') format('woff2'),
    url('opensans/open-sans-semi-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-semi-bold-latin.woff2') format('woff2'),
    url('opensans/open-sans-semi-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-semi-bold-cyrillic.woff2') format('woff2'),
    url('opensans/open-sans-semi-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: bold;
  font-display: swap;

  src: url('opensans/open-sans-bold-italic.woff2') format('woff2'),
    url('opensans/open-sans-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

  src: url('opensans/open-sans-bold-latin-extd.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;

  src: url('opensans/open-sans-bold-latin.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;

  src: url('opensans/open-sans-bold-cyrillic.woff2') format('woff2'),
    url('opensans/оpen-sans-bold.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;

  src: url('opensans/open-sans-extra-bold-italic.woff2') format('woff2'),
    url('opensans/open-sans-extra-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-display: swap;

  src: url('opensans/open-sans-extra-bold.woff2') format('woff2'),
    url('opensans/open-sans-extra-bold.woff') format('woff');
}

// Din Pro Condensed
@font-face {
  font-family: 'Din Pro';
  font-weight: 50;
  font-display: swap;

  src: url('din-pro-cond/din-pro-condensed-light.woff2') format('woff2'),
    url('din-pro-cond/din-pro-condensed-light.woff') format('woff');
}
@font-face {
  font-family: 'Din Pro';
  font-weight: 100;
  font-display: swap;

  src: url('din-pro-cond/din-pro-condensed-regular.woff2') format('woff2'),
    url('din-pro-cond/din-pro-condensed-regular.woff') format('woff');
}
@font-face {
  font-family: 'Din Pro';
  font-weight: 200;
  font-display: swap;

  src: url('din-pro-cond/din-pro-condensed-medium.woff2') format('woff2'),
    url('din-pro-cond/din-pro-condensed-medium.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  src: url('din-pro-cond/din-pro-condensed-bold-latin-extd.woff2')
      format('woff2'),
    url('din-pro-cond/din-pro-bold.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  src: url('din-pro-cond/din-pro-condensed-bold-latin.woff2') format('woff2'),
    url('din-pro-cond/din-pro-bold.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  src: url('din-pro-cond/din-pro-condensed-bold-cyrillic.woff2') format('woff2'),
    url('din-pro-cond/din-pro-bold.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  src: url('din-pro-cond/din-pro-condensed-black-latin-extd.woff2')
      format('woff2'),
    url('din-pro-cond/din-pro-condensed-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  src: url('din-pro-cond/din-pro-condensed-black-latin.woff2') format('woff2'),
    url('din-pro-cond/din-pro-condensed-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  src: url('din-pro-cond/din-pro-condensed-black-cyrillic.woff2')
      format('woff2'),
    url('din-pro-cond/din-pro-condensed-black.woff') format('woff');
}
