@import "./assets/fonts/fonts.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  //background-color: #1b493f;
}

body {
  margin: 0;
  background-color: white;
  overflow-x: hidden;

  &.footer-header-hidden {
    header:not(.header-404) {
      opacity: 0;
      pointer-events: none;
    }
    footer {
      display: none;
    }
  }
}

.m-sized-wrapper {
  margin: 0 auto;
  // max-width: 1200px;
  margin-left: 120px;
  margin-right: 120px;

  @media (max-width: 1240px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.form-error {
  font-family: "Open Sans";
  font-size: 15px;
  color: #ff7070;
  display: block;
  padding-bottom: 10px;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

:root {
  //for Reveal Scroll transitionX
  --shift-l: -100%;
  --shift-m: -30%;
  --shift-s: -10%;

  --unshift-l: 100%;
  --unshift-m: 30%;
  --unshift-s: 10%;
}
