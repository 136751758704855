@font-face {
  font-family: 'DIN Pro';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-black.woff2') format('woff2'),
    url('din-pro-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-condensed-light.woff2') format('woff2'),
    url('din-pro-condensed-light.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-italic.woff2') format('woff2'),
    url('din-pro-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-medium-italic.woff2') format('woff2'),
    url('din-pro-medium-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-condensed-medium-italic.woff2') format('woff2'),
    url('din-pro-condensed-medium-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  src: url('din-pro-condensed-black-latin-extd.woff2') format('woff2'),
    url('din-pro-condensed-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  src: url('din-pro-condensed-black-latin.woff2') format('woff2'),
    url('din-pro-condensed-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  src: url('din-pro-condensed-black-cyrillic.woff2') format('woff2'),
    url('din-pro-condensed-black.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('din-pro.woff2') format('woff2'), url('din-pro.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-light.woff2') format('woff2'),
    url('din-pro-light.woff') format('woff');
}

@font-face {
  font-family: 'Din Pro';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-bold.woff2') format('woff2'),
    url('din-pro-bold.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-medium.woff2') format('woff2'),
    url('din-pro-medium.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-condensed-regular.woff2') format('woff2'),
    url('din-pro-condensed-regular.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-light-italic.woff2') format('woff2'),
    url('din-pro-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('din-pro-condensed-medium.woff2') format('woff2'),
    url('din-pro-condensed-medium.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-black-italic.woff2') format('woff2'),
    url('din-pro-black-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-bold-italic.woff2') format('woff2'),
    url('din-pro-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-condensed-italic.woff2') format('woff2'),
    url('din-pro-condensed-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-condensed-bold-italic.woff2') format('woff2'),
    url('din-pro-condensed-bold-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-condensed-light-italic.woff2') format('woff2'),
    url('din-pro-condensed-light-italic.woff') format('woff');
}

@font-face {
  font-family: 'DIN Pro Cond';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('din-pro-condensed-black-italic.woff2') format('woff2'),
    url('din-pro-condensed-black-italic.woff') format('woff');
}
